export const editorConfiguration = {
  toolbar: [
    "bold",
    "italic",
    "heading",
    "|",
    "fontSize",
    "fontFamily",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "highlight",
    "horizontalLine",
    "|",
    "subscript",
    "superscript",
    "|",
    "alignment",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "outdent",
    "indent",
    "|",
    "todoList",
    "link",
    "blockQuote",
    "imageInsert",
    // "imageUpload",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "findAndReplace",
    "|",
    "htmlEmbed",
    "|",
    "textPartLanguage",
  ],
  theme: "dark",
};
